<template>
  <v-card class="pa-2">
    <v-row class="time-row">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <span id="dateFrom3">
          <v-text-field
            outlined
            dense
            type="text"
            append-icon="calendar_today"
            v-model="dateFrom"
            label=" تاریخ از "
            :editable="true"
            class="date-input"
          >
          </v-text-field>
        </span>

        <date-picker v-model="dateFrom" element="dateFrom3" color="#00a7b7" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <span id="dateTo3">
          <v-text-field
            outlined
            dense
            type="text"
            append-icon="calendar_today"
            v-model="dateTo"
            label=" تاریخ تا "
            :editable="true"
            class="date-input"
          >
          </v-text-field>
        </span>

        <date-picker v-model="dateTo" element="dateTo3" color="#00a7b7" />
      </v-col>
    </v-row>
    <v-row class="time-row">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <span id="timeFrom">
          <v-text-field
            outlined
            dense
            type="text"
            append-icon="schedule"
            v-model="timeFrom"
            label=" ساعت از "
            :editable="true"
            class="date-input"
          >
          </v-text-field>
        </span>

        <date-picker
          v-model="timeFrom"
          element="timeFrom"
          color="#00a7b7"
          type="time"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <span id="timeTo">
          <v-text-field
            outlined
            dense
            type="text"
            append-icon="schedule"
            v-model="timeTo"
            label=" ساعت تا "
            :editable="true"
            class="date-input"
          >
          </v-text-field>
        </span>

        <date-picker
          v-model="timeTo"
          element="timeTo"
          color="#00a7b7"
          type="time"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="submit-btn primary-btn"
          style="float: right"
          @click="filterDate()"
          :disabled="isBusy"
          >اعمال
        </v-btn>
      </v-col>
    </v-row>
    <hr />
    <v-row>
      <v-col>&nbsp;</v-col>
      <v-col>
        <v-btn
          style="float: left"
          class="secondary-btn mb-3 mt-2"
          :disabled="Items.length==0"
          @click="$refs.renderedExcel.$el.click()"
          >دریافت اکسل</v-btn
        >
        <vue-excel-xlsx
          v-show="false"
          ref="renderedExcel"
          :data="Items"
          :columns="excelFields"
          :filename="
            'لیست درخواست‌های ثبت شده ' + name +' از ' + dateFrom + ' تا ' + dateTo
          "
          :sheetname="currentDate"
        >
          دریافت اکسل
        </vue-excel-xlsx>
      </v-col>
    </v-row>
    <b-table
      responsive
      show-empty
      :fields="Fields"
      :items="Items"
      empty-text="در بازه زمانی انتخاب شده موردی برای نمایش وجود ندارد"
      empty-filtered-text="در بازه زمانی انتخاب شده موردی برای نمایش وجود ندارد"
      :busy="isBusy"
      :filter="Filter"
      :current-page="CurrentPage"
      :per-page="PerPage"
      @filtered="onFiltered"
    >
      <template v-slot:head()="data">
        <div style="text-align: center; vertical-align: middle">
          {{ data.label }}
        </div>
      </template>
      <template v-slot:cell()="data">
        <div style="text-align: center; vertical-align: middle">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(index)="data">
        <div style="text-align: center; vertical-align: middle">
          {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
        </div>
      </template>
      <template v-slot:cell(serviceCost)="data">
        <div style="text-align: center; vertical-align: middle">
          {{ Number(data.value).toLocaleString() }}
        </div>
      </template>
      <template v-slot:cell(share)="data">
        <div style="text-align: center; vertical-align: middle">
          {{ Number(data.value).toLocaleString() }}
        </div>
      </template>
      <div slot="table-busy" class="text-center primary--text my-2">
        <v-progress-circular
          indeterminate
          color="primary"
          class="align-middle"
        ></v-progress-circular>
      </div>
    </b-table>
    <v-pagination
      v-model="CurrentPage"
      :length="Math.ceil(TotalRows / PerPage)"
      :total-visible="5"
      prev-icon="arrow_back"
      next-icon="arrow_forward"
    ></v-pagination>
  </v-card>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  props: ["type","name"],
  components: { datePicker: VuePersianDatetimePicker },
  data() {
    return {
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      CurrentPage: 1,
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      isBusy: false,
      Items: [],
    };
  },
  methods: {
    filterDate() {
      //NOTE filtering date
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/services/serviceIdTurnover",
          {
            serviceId: this.$route.params.id,
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {
    Fields() {
      let fields;
      if (this.type == "clinic") {
        fields = [
          { key: "index", label: "#" },
          { key: "doctorName", label: "پزشک" },
          { key: "nurseName", label: "پرستار" },
          { key: "serviceCost", label: "قیمت (ریال)" },
          { key: "patientName", label: "بیمار" },
          { key: "date", label: "تاریخ" },
          { key: "receptionName", label: "کارمند پذیرش" },
          { key: "status", label: "وضعیت " },
        ];
      } else {
        fields = [
          { key: "index", label: "#" },
          { key: "doctorName", label: "دندانپزشک" },
          { key: "nurseName", label: "دستیار" },
          { key: "serviceCost", label: "قیمت (ریال)" },
          { key: "patientName", label: "بیمار" },
          { key: "date", label: "تاریخ" },
          { key: "receptionName", label: "کارمند پذیرش" },
          { key: "status", label: "وضعیت " },
        ];
      }
      return fields;
    },
    excelFields() {
      let fields;
      if (this.type == "clinic") {
        fields = [
          { field: "doctorName", label: "پزشک" },
          { field: "nurseName", label: "پرستار" },
          { field: "serviceCost", label: "قیمت (ریال)" },
          { field: "patientName", label: "بیمار" },
          { field: "date", label: "تاریخ" },
          { field: "receptionName", label: "کارمند پذیرش" },
          { field: "status", label: "وضعیت " },
        ];
      } else {
        fields = [
          { field: "doctorName", label: "دندانپزشک" },
          { field: "nurseName", label: "دستیار" },
          { field: "serviceCost", label: "قیمت (ریال)" },
          { field: "patientName", label: "بیمار" },
          { field: "date", label: "تاریخ" },
          { field: "receptionName", label: "کارمند پذیرش" },
          { field: "status", label: "وضعیت " },
        ];
      }
      return fields;
    },
  },
  mounted() {
    this.filterDate();
  },
};
</script>
